<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <h4><i class="nav-icon icon-layers"></i> Invoices</h4>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
            <input class="checkslots" slot=" " type="checkbox" slot-scope="props" />
            <span slot="amount" slot-scope="props">${{props.row.amount}}</span>
            <span slot="invoice" slot-scope="props"><a :href="props.row.invoice">{{props.row.invoice}}</a> ({{props.row.company}})</span>
            <span slot="status" slot-scope="props">
              <div v-if="props.row.status === 'Approved'">
                <span class="badge badge-success"><i class="fa fa-check"></i> Approved</span>
              </div>
              <div v-if="props.row.status === 'Draft'">
                <span class="badge badge-info"><i class="fa fa-paste"></i> Draft</span>
              </div>
              <div v-if="props.row.status === 'Pending'">
                <span class="badge badge-warning"><i class="fa fa-question"></i> Pending</span>
              </div>
              <div v-if="props.row.status === 'Reject'">
                <span class="badge badge-danger"><i class="fa fa-times"></i> Reject</span>
              </div>
            </span>
            <span slot="quickpay" slot-scope="props">
              <div v-if="props.row.quickpay === '1'">
                <span class="badge badge-success"><i class="fa fa-check"></i> Yes</span>
              </div>
            </span>

            <!-- <div slot="child_row" slot-scope="props">
              {{props.row.description}}</a>
            </div> -->
          </v-client-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable, Event} from 'vue-tables-2'

Vue.use(ClientTable)

export default {
  name: 'DataTable',
  components: {
    ClientTable,
    Event,
  },
  data () {
    return {
      columns: ['status', 'invoice', 'submitdate', 'amount', 'well', 'wss', 'wts'],
      data: [],
      today: new Date(),
      options: {
        headings: {
          status: 'Status',
          invoice: 'Invoice # (Company)',
          submitdate: 'Submitted Date',
          amount: 'Amount (CAD)',
          description: 'Description',
          well: 'Well License #',
          wss: 'WSS',
          wts: 'WTS',
        },
        oLanguage: {
          sSearch: "your-text-here"
        },
        sortable: ['status', 'invoice', 'submitdate', 'amount', 'description', 'well', 'wss', 'wts'],
        filterable: ['status', 'invoice', 'submitdate', 'amount', 'description', 'well', 'wss', 'wts'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false
    }
  },
}
</script>
