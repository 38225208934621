var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "12" } },
            [
              _c(
                "b-card",
                [
                  _c("h4", [
                    _c("i", { staticClass: "nav-icon icon-layers" }),
                    _vm._v(" Invoices")
                  ]),
                  _c("hr"),
                  _c("v-client-table", {
                    attrs: {
                      columns: _vm.columns,
                      data: _vm.data,
                      options: _vm.options,
                      theme: _vm.theme,
                      id: "dataTable"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: " ",
                        fn: function(props) {
                          return _c("input", {
                            staticClass: "checkslots",
                            attrs: { type: "checkbox" }
                          })
                        }
                      },
                      {
                        key: "amount",
                        fn: function(props) {
                          return _c("span", {}, [
                            _vm._v("$" + _vm._s(props.row.amount))
                          ])
                        }
                      },
                      {
                        key: "invoice",
                        fn: function(props) {
                          return _c("span", {}, [
                            _c("a", { attrs: { href: props.row.invoice } }, [
                              _vm._v(_vm._s(props.row.invoice))
                            ]),
                            _vm._v(" (" + _vm._s(props.row.company) + ")")
                          ])
                        }
                      },
                      {
                        key: "status",
                        fn: function(props) {
                          return _c("span", {}, [
                            props.row.status === "Approved"
                              ? _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "badge badge-success" },
                                    [
                                      _c("i", { staticClass: "fa fa-check" }),
                                      _vm._v(" Approved")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.status === "Draft"
                              ? _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "badge badge-info" },
                                    [
                                      _c("i", { staticClass: "fa fa-paste" }),
                                      _vm._v(" Draft")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.status === "Pending"
                              ? _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "badge badge-warning" },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(" Pending")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.status === "Reject"
                              ? _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "badge badge-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(" Reject")
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ])
                        }
                      },
                      {
                        key: "quickpay",
                        fn: function(props) {
                          return _c("span", {}, [
                            props.row.quickpay === "1"
                              ? _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "badge badge-success" },
                                    [
                                      _c("i", { staticClass: "fa fa-check" }),
                                      _vm._v(" Yes")
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ])
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }